import gql from 'graphql-tag';

export const GET_SHOPS = gql`
  query getShops {
    shops @rest(type: "Shop", path: "/shop", endpoint: "ss") {
      uuid
      name
      type
      imageUrl
      productsNumber
      deliveryFee
      open
      openingSchedule
      prettyId
      areaName
    }
  }
`;

export const GET_SHOPS_FOR_LOCATION = gql`
  query getShops($postcode: String) {
    shops(postcode: $postcode) @rest(type: "Shop", path: "/shop?postcode={args.postcode}", endpoint: "ss") {
      uuid
      name
      type
      imageUrl
      productsNumber
      deliveryFee
      open
      openingSchedule
      prettyId
      areaName
    }
  }
`;

// SINGLE SHOP

export const CHECK_ADDRESS_FOR_SHOP = gql`
  query checkAddress($shopId: String, $postcode: String) {
    shop(shopId: $shopId, postcode: $postcode)
    @rest(type: "Shop", method: "GET", path: "/shop/{args.shopId}?postcode={args.postcode}", endpoint: "ss") {
      uuid
      name
      type
      imageUrl
      addressLine1
      addressLine2
      postcode
      city
      deliveryFee
      open
      openingSchedule
      prettyId
      areaName
    }
  }
`;

export const GET_SHOP = gql`
  query getShops($shopId: String) {
    shop(shopId: $shopId) @rest(type: "Shop", path: "/shop/{args.shopId}", endpoint: "ss") {
      uuid
      name
      type
      imageUrl
      addressLine1
      addressLine2
      postcode
      city
      deliveryFee
      open
      openingSchedule
      prettyId
      areaName
    }
  }
`;

export const GET_PRETTY_SHOP = gql`
  query getShops($shopId: String) {
    shop(shopId: $shopId) @rest(type: "Shop", path: "/shop/pretty/{args.shopId}", endpoint: "ss") {
      uuid
      name
      type
      imageUrl
      largeImageUrl
      addressLine1
      addressLine2
      postcode
      city
      deliveryFee
      open
      openingSchedule
      prettyId
      areaName
      geolocation
      phoneNumber
    }
  }
`;
